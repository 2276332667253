
const SignatureCardB = ({ name,
    role,
    phoneNumber,
    company,
    image,
    url,
    addressLine1,
    addressLine2,
    addressLine3
}: any) => {

    return (
        <div className="signature-card">
            <table cellPadding="0" cellSpacing="0" id="signature-card" className="signature-card-container" style={{ verticalAlign: "-webkit-baseline-middle", fontSize: "medium", fontFamily: "Arial", background: 'white', borderRadius: '4px', padding: '16px', border: '1px solid rgba(197,197,197, .27)' }}>
                <tbody>
                    <tr>
                        <td>
                            <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', fontSize: 'medium', fontFamily: 'Arial' }}>
                                <tbody>
                                    <tr>
                                        {image ?
                                            <td width="80" style={{ verticalAlign: 'middle', paddingBottom: '8px' }}>
                                                <span style={{ marginRight: '12px', display: 'block' }}>
                                                    <img src={image} alt={{ company } + " Logo"} role="presentation" style={{ maxHeight: '64px' }} />
                                                </span>
                                            </td>
                                            :
                                            null
                                        }
                                        <td style={{ verticalAlign: 'middle', paddingBottom: '8px', paddingRight: '64px' }}>
                                            <h3 style={{ textAlign: 'left', margin: '0px', fontSize: '16px', color: 'rgb(0, 0, 0)' }}>{name}</h3>
                                            <p style={{ textAlign: 'left', margin: '0px', color: 'rgb(0, 0, 0)', fontSize: '12px' }}>{role}</p>
                                            <p style={{ textAlign: 'left', margin: '0px', fontWeight: '500', color: 'rgb(0, 0, 0)', fontSize: '14px', paddingTop: '4px' }}>{company}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ borderTop: '1px solid #ddd', paddingTop: '12px' }}>
                                            <table cellPadding="0" cellSpacing="0" style={{ verticalAlign: '-webkit-baseline-middle', fontSize: 'medium', fontFamily: 'Arial', paddingLeft: '8px', paddingRight: '8px' }}>
                                                <tbody>
                                                    {phoneNumber ?
                                                        <tr style={{ height: '18px', verticalAlign: 'top' }}>
                                                            <td width="20" style={{ textAlign: 'right', verticalAlign: 'top', fontSize: '12px', color: '#777', paddingRight: '4px' }}>tel:</td>
                                                            <td style={{ textAlign: 'left', verticalAlign: 'top', fontSize: '12px', color: '#777', padding: '0px' }}>
                                                                <span color="#777" style={{ textAlign: 'left', verticalAlign: 'top', fontSize: '12px', color: '#777' }}>
                                                                    {phoneNumber}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        :
                                                        null
                                                    }
                                                    {url ?
                                                        <tr style={{ height: '18px', verticalAlign: 'top' }}>
                                                            <td width="20" style={{ textAlign: 'right', verticalAlign: 'top', fontSize: '12px', color: '#777', paddingRight: '4px' }}>web:</td>
                                                            <td style={{ textAlign: 'left', verticalAlign: 'top', fontSize: '12px', color: '#777', textDecoration: 'none', padding: '0px' }}>
                                                                <a href={url} target="_blank" rel="noreferrer" color="#777" style={{ textAlign: 'left', verticalAlign: 'top', textDecoration: 'none', color: '#777', fontSize: '12px' }}>
                                                                    <span color='#777' style={{ textAlign: 'left' }}>{url}</span>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                        :
                                                        null
                                                    }
                                                    {addressLine1 ?
                                                        <tr style={{ height: "18px", verticalAlign: 'top' }}>
                                                            <td width="20" style={{ textAlign: 'right', verticalAlign: 'top', fontSize: '12px', color: '#777', paddingRight: '4px' }}>addr:</td>
                                                            <td style={{ verticalAlign: 'top', padding: '0px' }}>
                                                                <span color="#777" style={{ textAlign: 'left', verticalAlign: 'top', fontSize: '12px', color: '#777' }}>
                                                                    {addressLine1} {addressLine2 ? <><br />{addressLine2}</> : null} {addressLine3 ? <><br />{addressLine3}</> : null}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        :
                                                        null
                                                    }
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default SignatureCardB;