import { Link, Outlet, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import EmailSignature from './Pages/EmailSignature';
import './App.scss';
import Header from './Components/Layout/Header';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="email-signature" element={<EmailSignature />} />

        {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
}

export default App;

function Layout() {
  return (
    <div className='page-layout'>
      {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}
      {/* <Header /> */}
      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
      <div className='main-content'>
        <Outlet />
      </div>
      <div className='footer'>
        <div className='footer-copy'>
          DarkSide 2023
        </div>
      </div>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}