import { useState } from "react";
import { RiCloseFill } from "react-icons/ri";
import SignatureCardSwitcher from "../Components/SignatureVariations/SignatureCardSwitcher";


export default function EmailSignature() {
    const [signatureSelection, setSignatureSelection] = useState('cardA');
    const [name, setName] = useState('');
    const [role, setRole] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [company, setCompany] = useState('');
    const [image, setImage] = useState('');
    const [url, setUrl] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [addressLine3, setAddressLine3] = useState('');


    const handleNameChange = (item: any, e: any) => {
        switch (item) {
            case 'name':
                setName(e.target.value);
                break;
            case 'role':
                setRole(e.target.value);
                break;
            case 'number':
                setPhoneNumber(e.target.value);
                break;
            case 'company':
                setCompany(e.target.value);
                break;
            case 'url':
                setUrl(e.target.value);
                break;
            case 'addressLine1':
                setAddressLine1(e.target.value);
                break;
            case 'addressLine2':
                setAddressLine2(e.target.value);
                break;
            case 'addressLine3':
                setAddressLine3(e.target.value);
                break;
            default:
        }
    }

    function CopyToClipboard() {
        var range: any = document.createRange();
        let selection: any = window.getSelection();

        range.selectNodeContents(document.getElementById("signature-card"));

        selection.removeAllRanges(); // clear current selection
        selection.addRange(range); // to select text
        try {
            const content: any = document.getElementsByClassName("signature-card-container")[0].innerHTML;
            const blobInput = new Blob([content], { type: 'text/html' });
            const clipboardItemInput = new ClipboardItem({ 'text/html': blobInput });
            navigator.clipboard.write([clipboardItemInput]);
            console.log([clipboardItemInput])
        } catch (e) {
            // Handle error with user feedback - "Copy failed!" kind of thing
            console.log(e);
        }
        selection.removeAllRanges(); // to deselect
    }

    const handleImageChange = (e: any) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setImage(reader.result as string);
                //onImageChange(reader.result);
            };
        }
    };

    const clearImage = () => {
        let file: any = document.getElementById('image-input') as HTMLInputElement;
        file.value = null;
        setImage(file.value);
    }

    const handleStyleChange = (e: any) => {
        setSignatureSelection(e.target.value);
    }

    return (
        <div className="page__email-signature">
            <div className="left-drawer">
                <div className="instructions">
                    <b>Instructions:</b><br /><br />
                    Fill out your details,<br /><br />
                    Click the "Copy Signature" button,<br /><br />
                    Go to the signature section of your email client and paste in your new signature!<br /><br />
                    Need help setting up your Email Signature in Outlook? ... <a className="aLink" href="https://support.microsoft.com/en-us/office/create-and-add-an-email-signature-in-outlook-com-776d9006-abdf-444e-b5b7-a61821dff034" target="_blank" rel="noreferrer">Click here</a>
                </div>
                <hr className="instructions-hr" />
                <div className="form-fields-container">
                    <label>Full Name</label>
                    <input type="text" placeholder="Full Name" value={name} onChange={(e) => handleNameChange('name', e)} />
                    <label>Role</label>
                    <input type="text" placeholder="Role" value={role} onChange={(e) => handleNameChange('role', e)} />
                    <label>Company Name</label>
                    <input type="text" placeholder="Company Name" value={company} onChange={(e) => handleNameChange('company', e)} />
                    <label>Contact Number</label>
                    <input type="text" placeholder="Contact Number" value={phoneNumber} onChange={(e) => handleNameChange('number', e)} />
                    <label>Website Address</label>
                    <input type="text" placeholder="https://www.company.com" value={url} onChange={(e) => handleNameChange('url', e)} />
                    <label>Address Line 1</label>
                    <input type="text" placeholder="Address Line 1" value={addressLine1} onChange={(e) => handleNameChange('addressLine1', e)} />
                    <label>Address Line 2</label>
                    <input type="text" placeholder="Address Line 2" value={addressLine2} onChange={(e) => handleNameChange('addressLine2', e)} />
                    <label>Address Line 3</label>
                    <input type="text" placeholder="Address Line 3" value={addressLine3} onChange={(e) => handleNameChange('addressLine3', e)} />
                    <label>Company Logo</label>
                    <div className="attach-image-container">
                        <input type="file" id="image-input" className="image-input" onChange={(e) => handleImageChange(e)} />
                        {image ?
                            <div onClick={() => clearImage()} className="btn-clear-image"><RiCloseFill /></div>
                            :
                            null
                        }
                    </div>
                    {/* <label>Style</label>
                    <select className="style-select" onChange={(e) => handleStyleChange(e)}>
                        <option value="cardA">Card A</option>
                        <option value="cardB">Card B</option>
                    </select> */}
                </div>

                <div className="btn-copy-container">
                    <div onClick={() => CopyToClipboard()} className="btn-copy">Copy Signature</div>
                </div>
                <hr className="instructions-hr" />
            </div>
            <div className="preview-container">
                <div className="signature-preview-title">
                    Signature Preview
                </div>
                <div className="mock-email-client">
                    <div className="mec__header">New Message</div>
                    <div className="mec__to">Recipients</div>
                    <div className="mec__subject">Subject: Meeting Request</div>
                    <div className="mec__body">
                        <div className="mec__body_content">
                            Dear [Name of Recipient],
                            <br /><br />
                            I hope this email finds you well. I would like to request a meeting with you to discuss [Topic of Discussion]. Please let me know if you are available and what times work best for you.
                            <br /><br />
                            Thank you for your time and consideration.
                            <br /><br />
                            Best regards,
                            <br /><br />
                        </div>
                        <SignatureCardSwitcher
                            selection={signatureSelection}
                            name={name}
                            role={role}
                            phoneNumber={phoneNumber}
                            company={company}
                            image={image}
                            url={url}
                            addressLine1={addressLine1}
                            addressLine2={addressLine2}
                            addressLine3={addressLine3}
                        />
                    </div>
                    <div className="mec__footer">
                        <div className="btn-send">
                            Send
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}