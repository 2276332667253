import {ReactComponent as Logo} from '../logo.svg';

export default function Home() {

    return (
        <div className='page__home'>
            <Logo />
            <div className='title'>DarkSide</div>
        </div>
    )
}