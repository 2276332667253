import SignatureCardA from "./SignatureCardA";
import SignatureCardB from "./SignatureCardB";

const SignatureCardSwitcher = ({
    selection,
    name,
    role,
    phoneNumber,
    company,
    image,
    url,
    addressLine1,
    addressLine2,
    addressLine3
}: any) => {

    const whichSignature = (selection: any) => {
        switch (selection) {
            case 'cardA':
                return (
                    <SignatureCardA
                        name={name}
                        role={role}
                        phoneNumber={phoneNumber}
                        company={company}
                        image={image}
                        url={url}
                        addressLine1={addressLine1}
                        addressLine2={addressLine2}
                        addressLine3={addressLine3}
                    />
                )
            case 'cardB':
                return (
                    <SignatureCardB
                        name={name}
                        role={role}
                        phoneNumber={phoneNumber}
                        company={company}
                        image={image}
                        url={url}
                        addressLine1={addressLine1}
                        addressLine2={addressLine2}
                        addressLine3={addressLine3}
                    />
                )
            default:
                return null;
        }
    }

    return (
        <>
        {whichSignature(selection)}
        </>
    )
}

export default SignatureCardSwitcher;